@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    --bg-color: white;
    --site-primary: rgb(120, 68, 241);
    --site-highlight: rgb(101, 220, 220);
    --feedback-correct: #3c6;
    --feedback-incorrect: #d33;
}

* {
  margin:0;
  padding:0;
  box-sizing: border-box;
}
body{
  background-color: white;
  font-family: 'Poppins', sans-serif;
  background-image: url('./images/grid-bg-tr.png');
  background-position: 50% 0;
  background-size: 50%;
}
input[type="checkbox"],
input[type="checkbox"]+label{
  cursor:pointer;
}

/* ==========

 Typography

 ========== */
 .banner>*>a,
 .ex-submit__btn{
  color: white;
  text-decoration: none;
}
.complexity-symbol,
.ex-submit__btn:hover,
.ex-submit__btn:focus,
.asdf{
  font-weight: bold;
}
.fccategory__title,
.tut-category__title{
  font-size: 1.5rem;
  font-weight: bold;
}
.comp-card__sort-name{
  font-size: .825em;
}
.gif-wrapper,
.main-content-panel__graph-wrapper{
  color: azure;
}
.main-content-panel__graph-wrapper::before,
.gif-wrapper::before{
    color: black;  
}
.flashcards__submenu p,
.sidebar__checklist__title{
  font-size: 1.125rem;
  font-weight: bold;
}
.flashcards__scroller__arrow{
  font-size: 3rem;
  font-weight: bold;
}
.inline-icon{
  font-family: 'Courier New', Courier, monospace;
}
.flashcard__front,
.flashcard__back{
  color: black;
}
.flashcard__context{
  color: #222;
}
.article-editor-field-controls>button,
.article-editor-field-controls>div>button{
  font-weight: bold;
}
.editor-button{
  font-weight: bold;
}
.h2-editor-input{
  font-size: 1.25em;
  font-weight: 500;
}
.p-editor-input{
  font-size: 1em;
}
.tutorial__title h1,
.asdf{
  font-size: 2em;
}
.tutorial__title p,
.tut-category p,
.ex-info__details{
  color: #222;
  font-size: .825em;
}
.sidebar__checklist__category__link--unresponsive,
.sidebar__checklist__category__link,
.tutorial__body__related-title,
.ex-submit__btn{
  font-weight: 600;
}
.sidebar__checklist__category__link--unresponsive{
  color: grey;
}
.hint-section__reveal{
  color: gray;
  text-decoration: underline;
}
.hint-section{
  font-size: .825rem;
}

 /* ==========

 wrapper, banner

 ========== */
#wrapper{
  display: flex;
  flex-direction: column;
}
.banner{
  height: 10vh;
  background-color: var(--site-primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10rem;
  gap: 3rem;
  border-bottom: 3px solid rgb(143, 191, 226);
  position: sticky;
  top: 0;
  z-index: 99;
}

/* ==========

 tutorial hub,
 home

 ========== */
 .main-content-panel,
 .category-card-column{
  width: 85vw;
  background-color: white;
  min-height: 90vh;
  padding-block-end: 2vh;
  height: fit-content;
  margin-inline: auto;
  display: grid;
  grid-template-areas: "s m";
  grid-template-columns: 25% 65%;
  border-inline: 4px solid var(--site-primary);

}
.main-content-panel__main{
  grid-area: m;
  background-color: var(--bg-color);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-block-start: 4rem;
  padding-inline-end: 3rem;
  gap: 1rem;
}
.sidebar{
  grid-area: s;
  padding-block: 1rem;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-inline: auto 1rem;
  justify-content: center;
  position: fixed;
  align-self: flex-start;
  margin-inline-start: 2rem;
}
.sidebar--short{
  margin-block-start: 3rem;
}
.sidebar__checklist{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-block: 8px solid var(--site-primary);
  border-radius: 8px;
  border-inline: 2px solid gray;
  max-height: 70vh;
  overflow-y: auto;
  flex-direction: column;
  gap: .25rem;
}
.sidebar__checklist__checkbox{
  display: flex;
  gap: .25rem;
  align-items: flex-start;
}
.sidebar__checklist__checkbox input{
  height: 1.5rem;
}
.sidebar__checklist__category{
  padding-block: .25rem;
  margin-inline: .5rem;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  width: 90%;
}
.sidebar__checklist__category:not(:first-child){
  border-block-start: 2px solid black;
}
.wrapper--tut-category::after{
    height: 4px;
    width: 100%;
    background-color: var(--site-highlight);
    border-radius: 0 0 4px 4px;
    content: "";
    position: absolute;
    bottom: -9px;
    left: 0;
}
.wrapper--tut-category{
  border-radius: 3px 3px 4px 4px;
  position: relative;
  border-bottom: 4px solid rgb(166, 134, 241);
  cursor: pointer;
}

 /* ==========

 home

 ========== */
.complexity-symbol{
  flex: 8;
}
 /* ======sidebar====== */
.sidebar__speed-indicator{
  height: 2em;
}
.stack{
  overflow-y: scroll;
  padding: .5rem;
}
/* ======card====== */
.comp-card{
  text-align: center;
  margin-block: .5rem;
}
.wrapper--comp-card{
  border-bottom: 2px solid black;
}
.wrapper--comp-card:last-child{
  border: none;
}
.comp-card__subCard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.comp-card__sort-name{
  padding: .125rem;
  margin: .125rem;
  background-color: aliceblue;
}
.comp-card__sort-name:hover,
.comp-card__sort-name:focus{
  cursor: pointer;
}
.comp-card__name-select{
  display: flex;
}
.comp-checkbox,
.comp-card__placeholder{
  flex: 1;
}
/* ======descriptions====== */
.gif-wrapper,
.main-content-panel__graph-wrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  padding-inline-end: 2rem;
  padding: 1rem;
  background-color: var(--site-primary);
  z-index: 2;
  border-radius: 5px 5px 0 0;
  position: relative;
  border-bottom: 9px solid rgb(166, 134, 241);
}
.main-content-panel__graph-wrapper::before,
.gif-wrapper::before{
    position: absolute;
    top: -2em;
    left: 0;
    opacity: .5;
}
.main-content-panel__graph-wrapper::after,
.gif-wrapper::after{
    height: 8px;
    width: 100%;
    background-color: var(--site-highlight);
    border-radius: 0 0 16px 16px;
    content: "";
    position: absolute;
    bottom: -16px;
    left: 0;
}
.algGif,
.graph-space{
  width: 33%;
  min-width: 33%;
  max-width: 33%;
}
/* ======descriptions-- complexity section====== */
.main-content-panel__graph-wrapper::before{
    content: "Time Complexity:";
}
.graph-space{
  position: relative;
  aspect-ratio: 1 / 1;
  background-color: white;
}
.graph-space>*{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1 / 1;
  mix-blend-mode: darken;
}
/* ======descriptions-- algorithm section====== */
.gif-wrapper::before{
    content: "Algorithm:";
}
.gif-wrapper{
  margin-top: 2.5rem;
}
.algorithm-desc{
  overflow-y: auto;
}

/* ==========

 Flashcard Hub Page

 ========== */
.category-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    align-items: flex-start;
    justify-content: center;
}
.fccategory{
  border: 2px solid black;
  width: 80%;
  padding-inline: 1rem;
  justify-content: space-between;
  gap: 2rem;
  border-radius: 3px;
  cursor: pointer;
  /* aspect-ratio: 1 / 1.5; */
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: break-word;
  hyphens: auto;
}

/* ==========

 Flashcard Page

 ========== */
.flashcards{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flashcards__submenu{
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-basis: 30%;
  align-items: center;
}
.flashcards__submenu p{
  line-height: 2rem;
  cursor: pointer;
  vertical-align:middle;
}
.flashcards__scroller{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.flashcards__scroller__arrow{
    border: 1px solid black;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    width: 5rem;
    height: 5rem;
    text-align: center;
    background-color: white;
    cursor: pointer;
}


/* ======Flashcard====== */
.flashcard{
    width: 400px;
    height: 250px;
    margin: auto;
    background-color: transparent;
    perspective: 1000px;
    cursor: pointer;
    animation-duration: 0.5s;
}
.flashcard__inner{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.3s ease-out;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border: 2px solid black;
    background-color: white;
    border-radius: 5px;
}
.flashcard__front, .flashcard__back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1.5rem;
}
.flashcard__front{
    border-radius: inherit;
    background-color: white;
}
.flashcard__back{
    border-radius: inherit;
    background-color: #eee;
    transform: rotateY(180deg);
}
.flip {
    transform: rotateY(180deg);
}
.flashcard__front img{
  max-height: 60%;
}
@keyframes fcmoveright {
  0%   {
    transform: translateX(-2em);
    opacity: 0.5;
  }
  25%  {
    transform: translateX(-4em);
    opacity: 0;
  }
  50%  {
    transform: translateX(4em);
    opacity: 0;
  }
  95% {
    transform: translateX(0.125em);
    opacity: .9;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fcmoveleft {
  0%   {
    transform: translateX(2em);
    opacity: 0.5;
  }
  25%  {
    transform: translateX(4em);
    opacity: 0;
  }
  50%  {
    transform: translateX(-4em);
    opacity: 0;
  }
  95% {
    transform: translateX(-0.125em);
    opacity: .9;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

 /* ==========

 tutorial hub

 ========== */
 .category-card-column{
   flex-direction: column;
   justify-content: space-around;
 }
 .wrapper--tut-category{
  background-color: var(--site-primary);
  border-bottom-width: 9px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px
}
.tut-category{
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: .5rem
}


/* ==========

 individual tutorial page

 ========== */
 /* ======Article====== */
 .tutorial{
   gap: 0;
 }
 .tutorial__title,
 .tutorial__body{
  border-block-end: 2px solid black;
}
.tutorial__title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-block-end: .5rem;
}
.tutorial__body{
  padding-block-end: 1.5rem;
}
.wrapper--tutorial__title__heading{
  position: relative;
}
.wrapper--tutorial__title__heading::before{
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 8rem;
  height: 1.5rem;
  border-block-start: 4px solid var(--site-highlight);
  border-inline-start: 4px solid var(--site-highlight);
}
.wrapper--tutorial__title__heading::after{
  content: '';
  position: absolute;
  top: -5px;
  left: -15px;
  width: 7rem;
  height: 1rem;
  border-block-start: 4px solid var(--site-primary);
  border-inline-start: 4px solid var(--site-primary);
}
.tutorial__title__heading{
  position: relative;
  z-index: 2;
}
.wrapper--tutorial__title__categories{
  position: relative;
}
/* .wrapper--tutorial__title__categories::before{
  content: '';
  position: absolute;
  top: -5px;
  right: -10px;
  width: 4rem;
  height: 1.5rem;
  border-block-start: 4px solid var(--site-highlight);
  border-inline-end: 4px solid var(--site-highlight);
} */
.tutorial__h2,
.tutorial__p,
.tutorial__image--S,
.tutorial__image--M,
.tutorial__image--L{
  margin-block-start: 1rem;
}
.tutorial__h2{
  margin-block-start: 1rem;
  position: relative;
}
.tutorial__h2::before{
  content: '';
  position: absolute;
  left: -10px;
  width: 4px;
  height: 2rem;
  background-color: var(--site-highlight);
}
.tutorial__code{
  padding-inline: .5rem;
  width: fit-content;
  background-color: var(--site-highlight);
  white-space: pre-wrap
}
.tutorial__image--S{
  max-width: 25%;
}
.tutorial__image--M{
  max-width: 40%;
}
.tutorial__image--L{
  max-width: 80%;
}
.tutorial__related{
  padding-block: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

/* ======Sidebar====== */
.sidebar__checklist__title--margin{
  margin-inline-start: .5rem;
}
.sidebar__checklist__category__link,
.tutorial__body__related-title{
  cursor: pointer;
}
.sidebar__checklist__category__link--unresponsive,
.sidebar__checklist__category__link{
  max-width: 20ch;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}


/* ==========

 Exercise Page

 ========== */
 .exercise-controls{
  padding-block: .25rem;
  margin: .5rem;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  background-color: aliceblue;
  border: 1px solid black;
  width: 90%;
}
.exercise-controls__btn{
  display: flex;
  gap: 1ch;
  cursor: pointer;
  border-radius: 2px;
  padding-inline-end: 1rem;
}
.exercise-controls__btn:hover,
.exercise-controls__btn:focus{
  font-weight: bold;
}

/* ========== Exercise ========== */
.main-content-panel__main--ex-grid{
  display: grid;
  grid-template-areas: 
    "info info info"
    "back ex next"
    ". submit .";
    gap: 1rem;
    grid-template-columns: min-content 75% 15%;
    grid-template-rows: min-content minmax(35vh, max-content) min-content;
  width: 100%;
  max-width: 1500px;
}
.question-div{
  border: 2px solid gray;
  border-radius: 8px;
  grid-area: ex;
  width: 100%;
  min-height: 50%;
  height: fit-content;
  max-height: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.question-div__question{
  gap: .35rem;
  width: 100%;
}
.question-div__prompt{
  padding: .5rem;
  flex-basis: 0;
}
.grid-back,
.grid-next{
  align-self: center;
}
.grid-back{
  grid-area: back;
}
.grid-next{
  grid-area: next;
}
.ex-info{
  grid-area: info;
  border-block-end: 2px solid black;
  padding-block-end: .75rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ex-submit{
  grid-area: submit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.feedback-section,
.MP-Answers__wrapper{
  flex-grow: 1;
}
.ex-submit__btn{
  background-color: var(--site-primary);
  padding-block: 1rem;
  padding-inline: 1.5rem;
  border: 2px solid black;
  width: fit-content;
  height: fit-content;
  transition: background-color .5s;
  border-radius: 1rem;
  grid-area: submit;
  flex-grow: 0;
}
.ex-submit__btn:hover,
.ex-submit__btn:focus{
  background: linear-gradient(-90deg, var(--site-primary), transparent)  var(--site-highlight);
  cursor: pointer;
}
.exercise__code{
  padding: 1rem;
  width: 100%;
  background-color: var(--site-highlight);
  white-space: pre-wrap;
  flex-basis: 100%;
}
.hint-section{
  flex-basis: 0;
  padding: .5rem;
}
.hint-section__reveal:hover,
.hint-section__reveal:focus{
  cursor: pointer;
}
.feedback-section--correct{
  color: var(--feedback-correct);
}
.feedback-section--incorrect{
  color: var(--feedback-incorrect);
}
.feedback-section__ctc-row{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.MP-Answers{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  min-width: 25%;
  width: fit-content;
  max-width: 55%;
}
.MP-Answers__answer--selected,
.MP-Answers__answer,
.MP-Answers__answer--correct,
.MP-Answers__answer--incorrect{
  border-radius: 5px;
  width: 100%;
  padding-inline: 1rem;
  height: fit-content;
  word-wrap: break-word;
}
.MP-Answers__answer{
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(0, 0, 0);
}
.MP-Answers__answer:hover,
.MP-Answers__answer:focus{
  cursor: pointer;
  border: 2px solid black;
}
.MP-Answers__answer--selected{
  background-color: white;
  border: 3px solid rgb(0, 0, 0);
}
.MP-Answers__answer--correct{
  background-color: var(--feedback-correct);
  border: 3px solid #1a4;
  position: relative;
}
.MP-Answers__answer--incorrect{
  background-color: var(--feedback-incorrect);
  border: 3px solid #b11;
  position: relative;
}
.MP-Answers__answer--correct::after,
.MP-Answers__answer--incorrect::after{
  content: "";
  position: absolute;
  right: -30px;
  height: 24px;
  top: 0;
  aspect-ratio: 1/1;
}
.MP-Answers__answer--correct::after{
  background-image: url('./images/check-circle.svg');
}
.MP-Answers__answer--incorrect::after{
  background-image: url('./images/cross-circle.svg');
}
.SAInput{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 55%;
}
.SAInput__input{
  width: 100%;
  border: 2px solid black;
  height: 1.5rem;
  padding-inline: .5rem;
}
.SAInput--true{
  color: var(--feedback-correct);
}
.SAInput--false{
  color: var(--feedback-incorrect);
}
.SAInput__feedback{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
/* ==========

 Article Editor Page

 ========== */
.article-editor{
  border: 2px solid black;
  background-color: aliceblue;
  min-height: 80vh;
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
}
.article-editor-field{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.article-editor-field-controls{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
}
.article-editor-field-controls>button,
.article-editor-field-controls>div>button{
  height: 1.5rem;
  width: 1.5rem;
  background-color: rgba(240, 248, 255, 0.5);
  border-color: rgba(0,0,0,0.35);
}
.article-editor-field-controls>button:hover,
.article-editor-field-controls>div>button:hover{
  cursor: pointer;
}
.article-editor-field-controls-middle-row{
  display: flex;
  flex-direction: row;
  gap: .5rem;
}
.editor-controls{
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 25%;
  left: 10px;
  padding: .5rem;
  display: grid;
  grid-template-columns: auto auto;
}
.editor-button{
  padding: .25rem;
  border-radius: 5px;
  margin: .5rem;
}
.h2-editor-input{
  height: 1.25em;
}
.article-editor-img{
  height: 200px;
}